<template>
    <base-form models-name="utenti"
               :tabs="tabs"
               :new-model="newModel"
               :is-resouces-loading="isLoadingWorkspace || isLoadingCliente"
               @onSave="onSave"
    >

        <template #info="{model, form, isGlobalLoading}">
            <h-row justify="center">

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.DISATTIVAZIONE_MANUALE"
                                       :items="[ {label: 'ATTIVO', value: false}, {label: 'DISATTIVATO', value: true} ]"
                                       item-text="label"
                                       item-value="value"
                                       label="Stato"
                                       placeholder="Seleziona se abilitare o disabilitare"
                                       hint="Decidi se abilitare o disabilitare manualmente l'utenza"
                                       :disabled="!!isGlobalLoading || !$store.state.authModule.utente.CAN_ENABLE_UTENTE"
                                       prepend-icon="fa-toggle-on"
                    ></base-select-field>
                </h-col>

                <h-col v-if="$store.getters.isSystem" class="px-0" cols="9">
                    <base-form-field-api-select models-name="workspace"
                                                v-model="model.ID_WORKSPACE_FK"
                                                item-text="NOME"
                                                item-value="ID_REC"
                                                label="Workspace"
                                                placeholder="Seleziona un workspace"
                                                hint="Il workspace è essenziale e non sarà più modificabile"
                                                :disabled="!!isGlobalLoading || !!model.ID_REC"
                                                prepend-icon="fa-laptop-house"
                                                :rules="[v => !!v || 'Il workspace è obbligatorio!']"
                                                @finish="isLoadingWorkspace = false"
                    ></base-form-field-api-select>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.PROFILO"
                                       :items="['OPERATORE', !!$store.state.authModule.utente.CAN_CLIENTE ? 'CLIENTE' : undefined, 'COMMERCIALISTA']"
                                       label="Profilo"
                                       placeholder="Seleziona il profilo dell'utenza"
                                       hint="Il profilo dell'utenza determinerà le sua abilitazioni di base"
                                       :disabled="!!isGlobalLoading"
                                       prepend-icon="fa-user"
                                       :rules="[v => !!v || 'Il profilo è obbligatorio!']"
                                       @input="form.validate()"
                    ></base-select-field>
                </h-col>

                <h-col v-if="!!$store.state.authModule.utente.CAN_CLIENTE"
                       v-show="model.PROFILO === 'CLIENTE'"
                       class="px-0"
                       cols="9"
                >
                    <base-form-field-api-select models-name="clienti"
                                                v-model="model.ID_CLIENTE_FK"
                                                item-text="NOME"
                                                item-value="ID_REC"
                                                label="Cliente"
                                                placeholder="Seleziona un cliente"
                                                hint="Il cliente a cui associare l'utenza"
                                                :disabled="!!isGlobalLoading"
                                                prepend-icon="fa-users"
                                                :rules="[v =>  model.PROFILO !== 'CLIENTE' || !!v || 'Il cliente è obbligatorio!']"
                                                @finish="isLoadingCliente = false"
                    ></base-form-field-api-select>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-text-field v-model.trim="model.NOME"
                                     label="Nome"
                                     placeholder="Inserisci il nome anagrafico dell'utente"
                                     hint="Puoi trovare il nome su un documento di riconoscimento"
                                     maxlength="500"
                                     :disabled="!!isGlobalLoading"
                                     :rules="[v => !!v || 'Il nome è obbligatorio!']"
                                     prepend-icon="fa-signature"
                    ></base-text-field>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-text-field v-model.trim="model.COGNOME"
                                     label="Cognome"
                                     placeholder="Inserisci il cognome anagrafico dell'utente"
                                     hint="Puoi trovare il cognome su un documento di riconoscimento"
                                     maxlength="500"
                                     :disabled="!!isGlobalLoading"
                                     :rules="[v => !!v || 'Il cognome è obbligatorio!']"
                                     prepend-icon="fa-signature"
                    ></base-text-field>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-text-field v-model.trim="model.EMAIL"
                                     label="Email"
                                     placeholder="Inserisci l'email dell'utente"
                                     hint="Sull'email verrà spedita la password di accesso"
                                     maxlength="500"
                                     :disabled="!!isGlobalLoading"
                                     :rules="[v => !!v || 'L\'email è obbligatoria!',
                                              v => $store.getters.emailRegExp.test(v) || 'L\'email deve essere valida']"
                                     prepend-icon="fa-envelope"
                    ></base-text-field>
                </h-col>

                <h-col class="px-0" cols="9">
                    <h-row class="pa-5 pb-0" justify="space-between">

                        <h-col cols="auto" class="success--text" no-padding>
                            <v-icon color="success">fa fa-square</v-icon>
                            <span class="ml-3">RACCOMANDATO</span>
                        </h-col>

                        <h-col cols="auto" no-padding>
                            <v-icon>fa fa-square</v-icon>
                            <span class="ml-3">INDIFFERENTE</span>
                        </h-col>

                        <h-col cols="auto" class="warning--text" no-padding>
                            <v-icon color="warning">fa fa-square</v-icon>
                            <span class="ml-3">DA EVITARE</span>
                        </h-col>

                    </h-row>
                </h-col>

                <h-col class="px-0" cols="9">
                    <h-row v-for="(pp, index) in permissionsProfile" :key="index"
                           v-show="pp.profile.includes(model.PROFILO)"
                           style="border: 1px solid #dfdfdf;border-radius: 4px"
                           align="center"
                    >
                        <h-col cols="3" md="2" style="border-right: 1px solid #dfdfdf">
                            <h-row justify="center" align="center">
                                <h-col cols="10" md="5">
                                    <v-icon :color="pp[model.PROFILO]">{{ pp.icon }}</v-icon>
                                </h-col>
                                <h-col cols="10" md="5">
                                    <v-icon :color="pp[model.PROFILO]">{{ pp.icon2 }}</v-icon>
                                </h-col>
                            </h-row>
                        </h-col>
                        <h-col cols="6" md="8">
                            <h-row justify="end" align="center">
                                <h-col cols="auto" :class="!pp[model.PROFILO] ? undefined : (pp[model.PROFILO] + '--text')">
                                    <label :for="pp.key" style="cursor: pointer;">
                                        <i style="word-break: break-all;white-space:pre-wrap;">{{ pp.label }}</i>
                                    </label>
                                </h-col>
                            </h-row>
                        </h-col>
                        <h-col cols="3" md="2" style="border-left: 1px solid #dfdfdf">
                            <h-row justify="center" align="center">
                                <h-col class="ml-3" cols="auto">
                                    <v-checkbox v-if="$store.state.authModule.utente[pp.key]"
                                                :id="pp.key"
                                                class="ma-0 pa-0"
                                                v-model="model[pp.key]"
                                                :color="pp[model.PROFILO]"
                                                hide-details
                                                :disabled="!!isGlobalLoading"
                                    ></v-checkbox>
                                    <v-tooltip v-else bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="pr-3" v-on="on" :color="!model[pp.key] ? 'error' : 'success'">
                                                fa fa-{{ !model[pp.key] ? 'times' : 'check' }}
                                            </v-icon>
                                        </template>
                                        <span>Tu stesso non possiedi questo ruolo!</span>
                                    </v-tooltip>
                                </h-col>
                            </h-row>
                        </h-col>
                    </h-row>
                </h-col>

            </h-row>
        </template>

        <template #citta="{model, form, isGlobalLoading}">
            <h-row justify="center">

                <h-col class="px-0" cols="9">

                    <base-form-field-api-autocomplete models-name="citta"
                                                      v-model="model.zCitta"
                                                      label="Citta"
                                                      item-text="ID_REC"
                                                      placeholder="Digita per iniziare la ricerca"
                                                      disable-disabled
                                                      prepend-icon="fa-university"
                                                      filter-start="DENOMINAZIONE"
                                                      @input="model.zID_CITTA_FK = !!model.zCitta ? model.zCitta.ID_REC : null"
                    >
                        <template #item="{ item }">
                            {{ getDenominazione(item) }} {{ item.SIGLA }}
                        </template>
                        <template #selection="{ item }">
                            {{ getDenominazione(item) }} {{ item.SIGLA }}
                        </template>
                    </base-form-field-api-autocomplete>

                    <button-tooltip block
                                    color="warning"
                                    text="AGGIUNGI LIMITE CITTA"
                                    tooltip="Click per aggiungere una nuovo limite per la città selezionata"
                                    :disabled="cittàExists(model)"
                                    @click.stop="model.citta.push({ID_CITTA_FK: model.zID_CITTA_FK, DISTANZA: 50, testi: model.zCitta.testi}); panelCitta = model.citta.length-1"
                    ></button-tooltip>

                </h-col>

                <h-col class="px-0" cols="9">

                    <v-expansion-panels v-model="panelCitta">
                        <v-expansion-panel v-for="(ct, index) in model.citta" :key="index">

                            <v-expansion-panel-header>
                                <h-row>
                                    <h-col cols="8">
                                        <span>{{ getDenominazione(ct) }} ({{ ct.DISTANZA }} km)</span>
                                    </h-col>
                                </h-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <h-row>

                                    <h-col class="mb-6 pb-4" cols="12" no-padding>
                                        <button-tooltip block
                                                        color="error"
                                                        text="ELIMINA"
                                                        :tooltip="'Click per eliminare la citta ' + getDenominazione(ct)"
                                                        @click.stop="model.citta = model.citta.filter((r, i) => i !== index ); panelCitta = null"
                                        ></button-tooltip>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="ct.DISTANZA"
                                                         label="Distanza"
                                                         placeholder="Digita la distanza massima consentita"
                                                         hint="La distanza massima consentita parte dal centro della città"
                                                         maxlength="500"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !!v || 'La distanza del limite della città ' + (index+1) + ' è obbligatoria!',
                                                                  v => !v || /^(\d*\.)?\d+$/.test(v) || 'Il valore deve essere un decimale positivo!']"
                                                         prepend-icon="fa-people-arrows"
                                                         @input="form.validate();"
                                        ></base-text-field>
                                    </h-col>

                                </h-row>
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>

                </h-col>

            </h-row>
        </template>

    </base-form>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseForm from '@/components/aaaProject/form/BaseForm';
    import BaseFormFieldApiSelect from '@/components/aaaProject/form/BaseFormFieldApiSelect';
    import BaseFormFieldApiAutocomplete from '@/components/aaaProject/form/BaseFormFieldApiAutocomplete';
    import BaseSelectField from '@/components/aaaGenerics/form/BaseSelectField';
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';

    export default {
        name: 'FormUtente',
        components: {
            HRow,
            HCol,
            BaseForm,
            BaseFormFieldApiSelect,
            BaseFormFieldApiAutocomplete,
            BaseSelectField,
            BaseTextField,
            ButtonTooltip
        },
        data: (_this) => ({
            isLoadingWorkspace: !!_this.$store.getters.isSystem,
            isLoadingCliente: !!_this.$store.state.authModule.utente.CAN_CLIENTE,
            panelCitta: 0,
            tabs: [
                {name: 'info', title: 'INFO DI BASE', icon: 'fa-cube', showIf: true},
                {name: 'citta', title: 'LIMITI CITTA', icon: 'fa-university', showIf: true}
            ],
            newModel: {
                PROFILO: 'OPERATORE',
                DISATTIVAZIONE_MANUALE: !_this.$store.state.authModule.utente.CAN_ENABLE_UTENTE,
                citta: []
            },
            permissionsProfile: [
                {
                    key: 'CAN_SUGGERITO_EVENTO', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-gem',
                    label: 'Abilitazione per aggiungere/togliere tesori nascosti',
                    OPERATORE: 'warning',
                    CLIENTE: 'warning'
                },
                {
                    key: 'CAN_EVENTO', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-bars',
                    label: 'Abilitazione per accedere e vedere la sezione eventi',
                    CLIENTE: 'success'
                },
                {
                    key: 'CAN_CREATE_EVENTO', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-add',
                    label: 'Abilitazione per creare un nuovo evento',
                    CLIENTE: 'success'
                },
                {
                    key: 'CAN_EDIT_EVENTO', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-edit',
                    label: 'Abilitazione per modificare un evento',
                    CLIENTE: 'success'
                },
                {
                    key: 'CAN_EDIT_EVENTO_TESTI', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-edit',
                    label: 'Abilitazione per modificare i testi un evento'
                },
                {
                    key: 'CAN_EDIT_EVENTO_GALLERY', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-edit',
                    label: 'Abilitazione per modificare la galleria un evento'
                },
                {
                    key: 'CAN_EDIT_EVENTO_LINKS', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-edit',
                    label: 'Abilitazione per modificare i link un evento'
                },
                {
                    key: 'CAN_EDIT_EVENTO_GPX', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-edit',
                    label: 'Abilitazione per modificare il gpx un evento'
                },
                {
                    key: 'CAN_ENABLE_EVENTO', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-toggle-on',
                    label: 'Abilitazione per abilitare/disattivare un evento',
                    CLIENTE: 'warning'
                },
                {
                    key: 'CAN_DELETE_EVENTO', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-trash',
                    label: 'Abilitazione per eliminare un evento',
                    CLIENTE: 'warning'
                },
                {
                    key: 'CAN_OTHER_EVENTO', profile: ['OPERATORE', 'CLIENTE'],
                    icon: 'fa-calendar-alt', icon2: 'fa-handshake-simple',
                    label: 'Abilitazione per gestire gli eventi creati dagli altri',
                    OPERATORE: 'warning',
                    CLIENTE: 'warning'
                },
                {
                    key: 'CAN_UTENTE', profile: ['OPERATORE'],
                    icon: 'fa-user-cog', icon2: 'fa-bars',
                    label: 'Abilitazione per accedere e vedere la sezione utenti'
                },
                {
                    key: 'CAN_CREATE_UTENTE', profile: ['OPERATORE'],
                    icon: 'fa-user-cog', icon2: 'fa-add',
                    label: 'Abilitazione per creare una nuova utenza'
                },
                {
                    key: 'CAN_EDIT_UTENTE', profile: ['OPERATORE'],
                    icon: 'fa-user-cog', icon2: 'fa-edit',
                    label: 'Abilitazione per modificare un utenza'
                },
                {
                    key: 'CAN_ENABLE_UTENTE', profile: ['OPERATORE'],
                    icon: 'fa-user-cog', icon2: 'fa-toggle-on',
                    label: 'Abilitazione per abilitare/disattivare un utenza'
                },
                {
                    key: 'CAN_DELETE_UTENTE', profile: ['OPERATORE'],
                    icon: 'fa-user-cog', icon2: 'fa-trash',
                    label: 'Abilitazione per eliminare un utenza',
                    OPERATORE: 'warning'
                },
                {
                    key: 'CAN_OTHER_UTENTE', profile: ['OPERATORE'],
                    icon: 'fa-user-cog', icon2: 'fa-handshake-simple',
                    label: 'Abilitazione per gestire le utenze create dagli altri (SOLO grado minore)',
                    OPERATORE: 'warning'
                },
                {
                    key: 'CAN_CLIENTE', profile: ['OPERATORE', 'COMMERCIALISTA'],
                    icon: 'fa-users', icon2: 'fa-bars',
                    label: 'Abilitazione per accedere e vedere la sezione clienti',
                    COMMERCIALISTA: 'success'
                },
                {
                    key: 'CAN_CREATE_CLIENTE', profile: ['OPERATORE', 'COMMERCIALISTA'],
                    icon: 'fa-users', icon2: 'fa-add',
                    label: 'Abilitazione per creare un nuovo cliente'
                },
                {
                    key: 'CAN_EDIT_CLIENTE', profile: ['OPERATORE'],
                    icon: 'fa-users', icon2: 'fa-edit',
                    label: 'Abilitazione per modificare un cliente'
                },
                {
                    key: 'CAN_ENABLE_CLIENTE', profile: ['OPERATORE'],
                    icon: 'fa-users', icon2: 'fa-toggle-on',
                    label: 'Abilitazione per abilitare un cliente'
                },
                {
                    key: 'CAN_DELETE_CLIENTE', profile: ['OPERATORE'],
                    icon: 'fa-users', icon2: 'fa-trash',
                    label: 'Abilitazione per eliminare un cliente'
                },
                {
                    key: 'CAN_OTHER_CLIENTE', profile: ['OPERATORE'],
                    icon: 'fa-users', icon2: 'fa-handshake-simple',
                    label: 'Abilitazione per gestire i clienti creati dagli altri',
                    OPERATORE: 'warning'
                },
                {
                    key: 'CAN_CONTRATTO', profile: ['OPERATORE', 'COMMERCIALISTA'],
                    icon: 'fa-file-lines', icon2: 'fa-bars',
                    label: 'Abilitazione per accedere e vedere la sezione contratti',
                    COMMERCIALISTA: 'success'
                },
                {
                    key: 'CAN_CREATE_CONTRATTO', profile: ['OPERATORE', 'COMMERCIALISTA'],
                    icon: 'fa-file-lines', icon2: 'fa-add',
                    label: 'Abilitazione per creare un nuovo contratto',
                    COMMERCIALISTA: 'success'
                },
                {
                    key: 'CAN_EDIT_CONTRATTO', profile: ['OPERATORE', 'COMMERCIALISTA'],
                    icon: 'fa-file-lines', icon2: 'fa-edit',
                    label: 'Abilitazione per modificare un contratto'
                },
                {
                    key: 'CAN_DEFINITIVO_CONTRATTO', profile: ['OPERATORE', 'COMMERCIALISTA'],
                    icon: 'fa-file-lines', icon2: 'fa-file-signature',
                    label: 'Abilitazione per rendere definitivo un contratto',
                    COMMERCIALISTA: 'warning'
                },
                {
                    key: 'CAN_DELETE_CONTRATTO', profile: ['OPERATORE', 'COMMERCIALISTA'],
                    icon: 'fa-file-lines', icon2: 'fa-trash',
                    label: 'Abilitazione per eliminare un contratto'
                },
                {
                    key: 'CAN_OTHER_CONTRATTO', profile: ['OPERATORE', 'COMMERCIALISTA'],
                    icon: 'fa-file-lines', icon2: 'fa-handshake-simple',
                    label: 'Abilitazione per gestire i contratti creati dagli altri',
                    OPERATORE: 'warning',
                    COMMERCIALISTA: 'warning'
                }
            ]
        }),
        methods: {
            onSave({model, save}) {

                if (model.PROFILO !== 'CLIENTE') {
                    model.ID_CLIENTE_FK = undefined;
                }

                model.EMAIL = model.EMAIL.toLowerCase();

                for (const pp of this.permissionsProfile) {
                    if (!pp.profile.includes(model.PROFILO)) {
                        model[pp.key] = false;
                    } else {
                        model[pp.key] = !!model[pp.key];
                    }
                }

                save(model);

            },
            getDenominazione(citta) {

                let testo = citta.testi.find(t => t.ID_LINGUA_FK === this.$store.state.authModule.utente.ID_LINGUA_FK);

                if (!testo) {
                    testo = citta.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = citta.testi[0];
                }

                return testo.DENOMINAZIONE;

            },
            cittàExists(model) {

                if (!model.zID_CITTA_FK) {
                    return true;
                }

                for (const ct of model.citta) {

                    if (ct.ID_CITTA_FK === model.zID_CITTA_FK) {
                        return true;
                    }

                }

                return false;

            }
        }
    }
</script>

<style scoped>

</style>
